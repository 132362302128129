define("discourse/plugins/discourse-translator/discourse/components/language-switcher", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/dropdown-menu", "discourse/lib/cookie", "float-kit/components/d-menu", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _dropdownMenu, _cookie, _dMenu, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class LanguageSwitcher extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get localeOptions() {
      const targetLanguages = (this.siteSettings.automatic_translation_target_languages || "").split("|");
      return JSON.parse(this.siteSettings.available_locales).filter(_ref => {
        let {
          value
        } = _ref;
        return targetLanguages.includes(value);
      }).map(_ref2 => {
        let {
          name,
          value
        } = _ref2;
        return {
          label: name,
          value
        };
      });
    }
    async changeLocale(locale) {
      (0, _cookie.default)("locale", locale, {
        path: "/"
      });
      this.dMenu.close();
      // we need a hard refresh here for the locale to take effect
      window.location.reload();
    }
    static #_4 = (() => dt7948.n(this.prototype, "changeLocale", [_object.action]))();
    onRegisterApi(api) {
      this.dMenu = api;
    }
    static #_5 = (() => dt7948.n(this.prototype, "onRegisterApi", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DMenu
          @identifier="discourse-translator_language-switcher"
          title="Language switcher"
          @icon="language"
          class="btn-flat btn-icon icon"
          @onRegisterApi={{this.onRegisterApi}}
        >
          <:content>
            <DropdownMenu as |dropdown|>
              {{#each this.localeOptions as |option|}}
                <dropdown.item
                  class="discourse-translator_locale-option"
                  data-menu-option-id={{option.value}}
                >
                  <DButton
                    @translatedLabel={{option.label}}
                    @action={{fn this.changeLocale option.value}}
                  />
                </dropdown.item>
              {{/each}}
            </DropdownMenu>
          </:content>
        </DMenu>
      
    */
    {
      "id": "zeG3kMws",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,\"title\",\"Language switcher\"],[24,0,\"btn-flat btn-icon icon\"]],[[\"@identifier\",\"@icon\",\"@onRegisterApi\"],[\"discourse-translator_language-switcher\",\"language\",[30,0,[\"onRegisterApi\"]]]],[[\"content\"],[[[[1,\"\\n        \"],[8,[32,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"localeOptions\"]]],null]],null],null,[[[1,\"            \"],[8,[30,1,[\"item\"]],[[24,0,\"discourse-translator_locale-option\"],[16,\"data-menu-option-id\",[30,2,[\"value\"]]]],null,[[\"default\"],[[[[1,\"\\n              \"],[8,[32,2],null,[[\"@translatedLabel\",\"@action\"],[[30,2,[\"label\"]],[28,[32,3],[[30,0,[\"changeLocale\"]],[30,2,[\"value\"]]],null]]],null],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"        \"]],[1]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"dropdown\",\"option\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-translator/discourse/components/language-switcher.js",
      "scope": () => [_dMenu.default, _dropdownMenu.default, _dButton.default, _helper.fn],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = LanguageSwitcher;
});